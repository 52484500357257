import * as React from 'react'
import Icon from '../components/icon'
import * as Styles from './kontakty.module.sass'
import Seo from '../components/seo'

const KontaktyPage = () => {
  return (
    <>
      <Seo
        title={`Контакты`}
      />
      <h1 className={`is-size-1 is-size-3-touch mainheader`}>Контакты</h1>
      <div className={`tile is-ancestor`}>
        <div className={`tile is-half is-parent`}>
          <div className={`tile is-child is-flex is-flex-direction-column is-justify-content-space-aroud`}>
            <h3 className={`is-size-3 is-size-4-touch ${Styles.subheader}`}>Для посещения</h3>
            <div className={`${Styles.subitem}`}>
              <p className={`text-primary`}>Кострома, Россия<br />улица Сенная дом 4а</p>
            </div>
            <div className={`${Styles.subitem}`}>
              <a className={`is-underlined`} href={`mailto:house@kastorskyjewelry.ru`}>house@kastorskyjewelry.ru</a>
            </div>
            <div className={`is-hidden-touch ${Styles.subitem}`}>
              &nbsp;
            </div>
          </div>
        </div>
        <div className={`tile is-half is-parent`}>
          <div className={`tile is-child is-flex is-flex-direction-column is-justify-content-space-aroud`}>
            <h3 className={`is-size-3 is-size-4-touch ${Styles.subheader}`}>По всем вопросам</h3>
            <div className={`${Styles.subitem}`}>
              <p className={`text-primary`}>
                <a className={`is-underlined`} href={`tel:+79952672575`}>+7 (995) 267-25-75</a>
                <span className={`is-hidden-touch`}><br /><br /></span>
              </p>
            </div>
            <div className={`${Styles.subitem}`}>
              <a className={`is-underlined`} href={`mailto:ilya@kastorskyjewelry.ru`}>ilya@kastorskyjewelry.ru</a>
            </div>
            <div className={`${Styles.subitem}`}>
              <a aria-label={`Whatsapp`} target={`_blank`} href={`https://api.whatsapp.com/send?phone=79952672575`} className={`pr-4`}><Icon className={`icon ${Styles.customsize} is-size-4 fa`} icon={["fab", "whatsapp"]}></Icon></a>
              <a aria-label={`Telegram`} target={`_blank`} href={`https://t.me/kastorskyjewelry`} className={`pr-4`}><Icon className={`icon ${Styles.customsize} is-size-4 fa`} icon={["fab", "telegram-plane"]}></Icon></a>
              <a aria-label={`Vk.com`} target={`_blank`} href={`https://vk.com/kastorskyjewelry`} className={`pr-3 pl-1`}><Icon className={`icon ${Styles.customsize} is-size-4 fa`} icon={["fab", "vk"]}></Icon></a>
            </div>
          </div>
        </div>
      </div>
{/*       <div className={`tile is-ancestor`}>
        <div className={`tile is-half is-parent`}>
          <div className={`tile is-child is-flex is-flex-direction-column is-justify-content-space-aroud`}>
            <h3 className={`is-size-3 is-size-4-touch ${Styles.subheader}`}>Социальные сети</h3>
            <div className={`${Styles.subitem} ${Styles.lastrow}`}>
              <a aria-label={`Facebook`} target={`_blank`} href={`https://www.instagram.com/kastorskyjewelry`} className={`pr-3 pl-1`}><Icon className={`icon ${Styles.customsize} is-size-4 fa`} icon={["fab", "instagram"]}></Icon></a>
              <a aria-label={`Instagram`} target={`_blank`} href={`https://www.facebook.com/kastorskyjewelry`}><Icon className={`icon ${Styles.customsize} is-size-4 fa`} icon={["fab", "facebook-f"]}></Icon></a>
            </div>
            <div className={`${Styles.subitem}`}>
              &nbsp;
            </div>
            <div className={`${Styles.subitem}`}>
              &nbsp;
            </div>
          </div>
        </div>
      </div> */}
    </>
  )
}

export default KontaktyPage
